import { generateImageUrl, ICommerceApiSettings } from '@msdyn365-commerce/core';
/**
 * Parses whether a URL is valid
 * @param url
 * @returns
 */
export const isValidImage = (url?: string): boolean => {
    if (url) {
        return url.startsWith('https') || url.startsWith('Products');
    } else {
        return false;
    }
};

export async function validateUrlsAsync(
    urls: string[],
    apiSettings: ICommerceApiSettings,
    filterLegacy: boolean = false
): Promise<boolean> {
    var results: Promise<Boolean>[] = [];
    var isValid = false;
    for (const url of urls) {
        results.push(validateUrlAsync(url, apiSettings, filterLegacy));
    }
    await Promise.all(results).then(values => {
        //If any of the values are true, the image is valid
        isValid = values.some(value => value);
    });

    return isValid;
}

export async function validateUrlAsync(url: string, apiSettings: ICommerceApiSettings, filterLegacy: boolean = false): Promise<boolean> {
    //If we filter legacy images and the image is a legacy image, we know it is not valid
    if (filterLegacy && !isValidImage(url)) {
        return false;
    }

    const imageUrl = generateImageUrl(apiSettings, url);

    if (imageUrl === '') {
        return false;
    }

    return new Promise<boolean>(resolve => {
        try {
            const http = new XMLHttpRequest();
            http.open('HEAD', imageUrl ?? '', true);

            http.addEventListener('load', () => {
                resolve(http.status === 200 || http.status === 201);
            });

            http.addEventListener('error', () => {
                resolve(false);
            });

            http.send();
        } catch {
            resolve(false);
        }
    });
}
